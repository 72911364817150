import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import { BrowserRouter, Switch, Route} from 'react-router-dom';

import User from "./components/Users/User";
import Client from "./components/Clients/Client";
import Commerce from "./components/Commerces/Commerce";
import Payment from "./components/Payments/Payment";
import Product from "./components/Products/Product";
import Confirmation from "./components/Payments/Confirmation";
import Sales from "./components/Reports/Sales";
import Menu from './components/Utils/Menu';
import Bye from './components/Users/Bye';
import Profile from './components/Users/Profile';
import "./index.css"



function setToken(userToken) {

  sessionStorage.setItem('token', userToken);
  window.location.reload(false);
}

function getToken() {
  return sessionStorage.getItem('token');
}

function App() {
  const token = getToken();
  const location = document.location.pathname.indexOf("confirma");

  if((!token || token === "" || token === undefined || token === null) && location === -1) {
    return <Login setToken={setToken} />
  }


  return (
    <BrowserRouter>
      {location === -1 &&
        <Menu/>
      }
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/users" component={User} />
        <Route exact path="/commerces" component={Commerce} />
        <Route exact path="/clients" component={Client} />
        <Route exact path="/pagos" component={Payment} />
        <Route exact path="/productos" component={Product} />
        <Route exact path="/confirmacion/:success" component={Confirmation}/>
        <Route exact path="/confirmacion/" component={Confirmation}/>
        <Route exact path="/reports/sales" component={Sales} />
        <Route exact path="/users/logout" component={Bye} />
        <Route exact path="/users/profile" component={Profile} />


        <Route
          render={function () {
            return <h1>Not Found</h1>;
          }}
        />
      </Switch>
    </BrowserRouter>

  );
}

export default App;