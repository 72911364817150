import React, { Component } from "react";
import i18, { setDefaultNamespace } from 'i18next';

import { MDBContainer, MDBCardBody, MDBCard,MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBInput, 
  MDBModalHeader, MDBRow, MDBCol, MDBAlert, ToastContainer,MDBBreadcrumb,MDBBreadcrumbItem,
  MDBSelect, MDBSelectInput, MDBSelectOption, MDBSelectOptions, MDBInputGroup,MDBIcon, MDBAutocomplete
  } from "mdbreact";
  var env2 = require("../../config/env.js")

 // var CryptoJS = require("crypto-js");

  var current_currency = {code: "EUR", rate: 1, locate: "es-CU", last_date_rate: ""};

  const errorColor = {
    "100": ["info","spinner"],
    "200": ["success","check"],
    "300": ["warning","exclamation"],
    "500": ["danger","exclamation-triangle"]
  };

  const $ = require('jquery');  

  $.DataTable = require('datatables.net');
  require("datatables.net-responsive");
  
  var table = null;

  const columns= [
    {
      title: 'Servicio',
      data: 'name'
    },
    {
      title: 'Descripción',
      data: 'description'
    },
    {
      title: 'Precio',
      data: 'amount'
    }
    ];

  
export default class Payment extends Component {

  componentWillUnmount() {
    $(document).unbind();
  }


  componentDidMount() {
    table = $(this.refs.main).DataTable({
      columns,
      data: [],
      ordering: false,
      processing: true,
      paging: false,
      info: false,
      select: true,
      createdRow: function( row, data ) {
        $( row ).attr('data-name', data.name);
        $( row ).attr('data-description', data.description);
        $( row ).attr('data-amount', data.amount);
      }, 
      columnDefs: [
        
      ],
      lengthChange: false,
      language: {
        search: "Buscar",
        paginate: {
          previous: "&lang;",
          next: "&rang;"
        },
        info: "_START_-_END_ de _TOTAL_",
        infoFiltered: "",
        emptyTable: "Aún no hay datos",
        infoEmpty: "0 de 0"

      }
  
    });
    this.tableRefresh();
  }
  
  tableRefresh() {
    
    table.clear();

  
    table.rows.add( [
      {name: "Asistente personal telefónico", value: "Asistente personal telefónico", description: "Orientación en temas relacionados con el bienestar", amount: "25,00 EUR"},
      {name: "Asesoramientos nutricionales", value: "Asesoramientos nutricionales", description: "Orientación sobre nutrición adecuada", amount: "30,00 EUR"}, 
      {name: "Asesoramientos psicológicos", value: "Asesoramientos psicológicos", description: "Orientación psicológicos para mejorar el bienestar humano", amount: "30,00 EUR"}, 
      {name: "Acompañamiento para el bienestar", value: "Acompañamiento para el bienestar", description: "Orientación sobre servicios que existen para el bienestar", amount: "30,00 EUR"}, 
      {name: "Servicios de proximidad", value: "Servicios de proximidad", description: "Orientación sobre servicios de bienestar próximos a una localidad.", amount: "25,00 EUR"},
      {name: "Consultas integrales", value: "Consultas integrales", description: "Orientación sobre temas en hábitos saludables", amount: "25,00 EUR"},
      {name: "Servicios sociales", value: "Servicios sociales", description: "Orientación sobre servicios orientados a mejorar el bienestar social", amount: "30,00 EUR"}
    ]).draw();
  }



  state = {
    
    products: [
      {name: "Asistente personal telefónico", value: "Asistente personal telefónico"},
      {name: "Asesoramientos nutricionales", value: "Asesoramientos nutricionales"}, 
      {name: "Asesoramientos psicológicos", value: "Asesoramientos psicológicos"}, 
      {name: "Acompañamiento para el bienestar", value: "Acompañamiento para el bienestar"}, 
      {name: "Servicios de proximidad", value: "Servicios de proximidad"},
      {name: "Consultas integrales", value: "Consultas integrales"},
      {name: "Servicios sociales", value: "Servicios sociales"}
    ]
  };



  render() {
    let search = window.location.search;
    let isRole = 0;
    return (
        <div>
            <MDBContainer>
              
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>Productos</MDBBreadcrumbItem>
                
              </MDBBreadcrumb>        

              <MDBCard className="card-transparent">
                <MDBCardBody className="table-act">
                  <table ref="main" />

                </MDBCardBody>
              </MDBCard> 
            </MDBContainer>
        </div>

    );
  }
}
 