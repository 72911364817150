import React, { Component } from "react";

import { MDBContainer , MDBRow, MDBCol, MDBSelect, MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBInput, 
  MDBModalHeader, MDBBreadcrumb, MDBBreadcrumbItem,MDBSelectOption
} from "mdbreact";

import {get, update, updatepass} from "./functions";
import {list as listcommerces} from "../Commerces/functions";
import moment from "moment";

var roles = [{value: 0, text: "Usuario Comercio"},{ value:1, text: "Administrador Comercio"}]

const $ = require('jquery');  


export default class Profile extends Component {

  componentWillUnmount() {
  }
 
  componentDidMount() {
    var tt = this;


    let myPromise = new Promise((resolve, reject) => {
      const pp = listcommerces();
      resolve(pp);
    });

    myPromise.then( (results) => {
      this.setState({
        commerces: results.data.map(object => ({text: object.name, value: object.id})),
      }, () => {
        let myPromise2 = new Promise((resolve, reject) => {
          const pp = get( sessionStorage.getItem("token"));
          resolve(pp);
        });
        
        myPromise2.then( (results) => {
          if (results.data.length !==0) {
            this.setState({
              item: {
                id: results.data[0].id,
                name: results.data[0].name,
                email: results.data[0].email,
                username: results.data[0].username,
                commerce_id: results.data[0].commerce_id,
                commercename: tt.state.commerces.filter(function(item) { return item.value === results.data[0].commerce_id })[0]["text"],
                role: results.data[0].role,
                rolename: roles.filter(function(item) { return item.value === results.data[0].role })[0]["text"]
              },
              modal16: true
            }, () => {
            });
          }  
        });
      })

    });



    
    this.setState({
      moment: moment()
    })



  }



  state = {
    sid: "",
    token: "",
    modal16: false,
    uid: "",
    commerces: [],
    item: {
      id: "",
      name: "",
      email: "",
      username: "",
      role: 0,
      commerce_id: 0
    }
  };


  
  toggle = nr => () => {
    if (nr==16)
      this.clearForm();

    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

  goBack = nr => () => {
    window.location.href = sessionStorage.getItem('previous-page');
  }

 
  
  changeHandler = event => {
    const {name, value} = event.target;
    this.state.item[name] = value;
  };

  
  submitHandler = event => {
    event.preventDefault();
    var tt = this;

    var obj = {
      id: this.state.item.id,
      name: this.state.item.name,
      email: this.state.item.email,
      username: this.state.item.username,
      password: this.state.item.password,
      commerce_id: this.state.item.commerce_id,
      role: this.state.item.role
    }

    let myPromise = new Promise((resolve, reject) => {
      const pp = update(this.state.item.id, obj);
      resolve(pp);
    });
    myPromise.then( (results) => {
      window.location.href = sessionStorage.getItem('previous-page');
    });    

  };

  submitPassword
  = event => {
    event.preventDefault();
    var tt = this;

    var obj = {
      id: this.state.item.id,
      password: this.state.item.password,
    }

    let myPromise = new Promise((resolve, reject) => {
      const pp = updatepass(this.state.item.id, obj);
      resolve(pp);
    });
    myPromise.then( (results) => {
  
    }); 
    

  };
  onChangeCommerce = event => {
    this.state.item.commerce_id = event[0]
  }
  onChangeRole= event => {
    this.state.item.role = event[0]
  }

  render() {

    return (
        <div>
          <MDBContainer>
            <MDBBreadcrumb>
              <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
              <MDBBreadcrumbItem active>Usuarios</MDBBreadcrumbItem>
            </MDBBreadcrumb>

            <MDBModal isOpen={this.state.modal16} backdrop={false}>
              <form onSubmit={this.submitHandler}>
                <MDBModalHeader className="modal-header-form">
                  Editar perfil  
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBRow>
                      <MDBCol>
                        <MDBRow>
                          <MDBCol className="col-lg-3">
                            <div className="form_field_label">Nombre</div>
                          </MDBCol>
                          <MDBCol>
                            <MDBInput
                            type="text"
                            validate
                            name="name"
                            placeHolder="Nombre del usuario"
                            size="20"
                            error="wrong"
                            required
                            onChange={this.changeHandler}
                            valueDefault={this.state.item.name}
                            success="right"
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <MDBRow>
                          <MDBCol className="col-lg-3">
                            <div className="form_field_label">Email</div>
                          </MDBCol>
                          <MDBCol>
                            <MDBInput
                            type="text"
                            validate
                            name="email"
                            placeHolder="Dirección de correo electrónico"
                            size="20"
                            error="wrong"
                            required
                            onChange={this.changeHandler}
                            valueDefault={this.state.item.email}
                            success="right"
                            />
                          </MDBCol>
                        </MDBRow>                          
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <MDBRow>
                          <MDBCol className="col-lg-3">
                            <div className="form_field_label">Usuario</div>
                          </MDBCol>
                          <MDBCol>
                            <MDBInput
                            disabled={this.state.item.role ===0}
                            type="text"
                            validate
                            name="username"
                            placeHolder=""
                            size="20"
                            error="wrong"
                            required
                            onChange={this.changeHandler}
                            valueDefault={this.state.item.username}
                            success="right"
                            />                              
                          </MDBCol>
                        </MDBRow>                          
                      </MDBCol>
                    </MDBRow>
                   <MDBRow style={{ padding: "0 20px 20px 0px"}}><MDBCol><a onClick={this.toggle(2)}  className="form_field_label" style={{color: "blue", fontSize: "12px"}}>Restablecer contraseña</a></MDBCol></MDBRow>

                    <MDBRow>
                      <MDBCol>
                        <MDBRow>
                          <MDBCol className="col-lg-3">
                            <div className="form_field_label">Rol</div>
                          </MDBCol>
                          <MDBCol>
                            {this.state.item.role ===0 &&
                            (
                              <MDBSelect><MDBSelectOption disabled >{this.state.item.rolename}</MDBSelectOption></MDBSelect>
                            )}
                            {this.state.item.role !==0 &&
                              (<MDBSelect disabled={this.state.item.role!==1}
                                    options= {roles}
                                    getValue={this.onChangeRole}
                                    selected= {this.state.item.rolename}
                                  >
                              </MDBSelect>
                            )}
                          </MDBCol>
                        </MDBRow>                          
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <MDBRow>
                          <MDBCol className="col-lg-3">
                            <div className="form_field_label">Comercio</div>
                          </MDBCol>
                          <MDBCol>
                          {this.state.item.role ===0 &&
                            (
                              <MDBSelect><MDBSelectOption disabled >{this.state.item.commercename}</MDBSelectOption></MDBSelect>
                            )}
                            {this.state.item.role !==0 &&
                            (
                              <MDBSelect disabled={this.state.item.role!==1}
                                    options= {this.state.commerces}
                                    getValue={this.onChangeCommerce}
                                    selected= {this.state.item.commercename}
                                  />
                            )}
                          </MDBCol>
                        </MDBRow>                          
                      </MDBCol>
                    </MDBRow>

                </MDBModalBody>
                <MDBModalFooter>
                <MDBBtn className="btn-cancel-1" onClick={this.goBack()}>Cerrar</MDBBtn>
                  <MDBBtn type="submit" className="btn-confirm-1">Guardar</MDBBtn>
                </MDBModalFooter>
              </form>
          </MDBModal>
          <MDBModal size="sm" isOpen={this.state.modal2} toggle={this.toggle(2)} >
              <form onSubmit={this.submitPassword}>

              <MDBModalHeader className="modal-header-form">
                Restablecer contraseña
              </MDBModalHeader>
              <MDBModalBody>
                <MDBContainer>
                <MDBRow>
                  <MDBCol>
                    <MDBRow>
                      <MDBCol className="col-lg-3">
                        <div class="form_field_label">Contraseña</div>
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                        type="password"
                        validate
                        name="password"
                        placeHolder=""
                        size="20"
                        error="wrong"
                        required
                        onChange={this.changeHandler}
                        valueDefault={this.state.item.password}
                        success="right"
                        />
                      </MDBCol>
                    </MDBRow>                        
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBRow>
                      <MDBCol className="col-lg-3">
                        <div class="form_field_label">Repite contraseña</div>
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                        type="password"
                        validate
                        name="repeatpassword"
                        placeHolder=""
                        size="20"
                        error="wrong"
                        required
                        onChange={this.changeHandler}
                        valueDefault={this.state.item.repeatpassword}
                        success="right"
                        />
                      </MDBCol>
                    </MDBRow>                        
                  </MDBCol>
                </MDBRow> 
                </MDBContainer>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn className="btn-cancel-1" onClick={this.toggle(2)}>CERRAR</MDBBtn>
                <MDBBtn type="submit" className="btn-confirm-1">ACTUALIZAR</MDBBtn>
              </MDBModalFooter>
              </form>
            </MDBModal>   
          </MDBContainer>

        </div>

    );
  }
}
 