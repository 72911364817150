// config.js
module.exports = {
  TOKEN_SECRET: process.env.TOKEN_SECRET || "espacioNus2022$",
  DICTIONARY: {
    PAYMENT_METHOD: ["Enseguida","Aplazado",],
    GATEWAY: ["Segura","No Segura"],
    STATE: ["Pendiente", "Completado","Rechazado", "N/A"]
  }

};
  