import React, { Component } from "react";

import { MDBContainer, MDBCardBody, MDBCard, MDBCardHeader,MDBBtn, MDBIcon, MDBModal, MDBModalBody, MDBModalFooter, MDBInput, 
  MDBModalHeader, MDBRow, MDBCol,
  MDBAlert,
  toast, ToastContainer,MDBBreadcrumb,MDBBreadcrumbItem,
  MDBDatePicker
} from "mdbreact";

import {sales} from "./functions";

const config = require("../Utils/config")
const moment = require("moment")
const $ = require('jquery');
$.DataTable = require('datatables.net');
require("datatables.net-responsive");
require('datatables.net-buttons/js/buttons.html5.min.js' )(this, $); 
var table = null;

const columns= [
  {
    title: 'id',
    data: 'id'
  },
  {
      title: 'Fecha',
      data: 'date'
  },
  {
      title: 'Transacción',
      data: 'transaction_number'
  },
  {
      title: 'Cliente',
      data: 'client'
  },
  {
      title: 'Importe',
      data: 'amount'
  },
  {
      title: 'Cambio',
      data: 'exchange_rate'
  },
  {
      title: 'Total',
      data: 'total'
  },
  {
      title: 'Pago',
      data: 'payment_method'
  },
  {
      title: 'Estado',
      data: 'state'
  }
];

  


export default class Cliente extends Component {

  componentWillUnmount() {
    $(document).unbind();
  }

  componentDidMount() {
    var tt = this;
    table = $(this.refs.main).DataTable({
      columns,
      data: [],
      ordering: true,
      processing: true,
      responsive: true,
      select: false,
      createdRow: function( row, data ) {
        $( row ).attr('data-id', data.objectId);
      }, 
      columnDefs: [
        {
            targets: [ 0 ],
            visible: false,
            searchable: false
        },
        {
          targets: [4,5,6],
          className: "text-right",
        }
      ],
      lengthChange: false,
      language: {
        search: "Buscar",
        paginate: {
          previous: "&lang;",
          next: "&rang;"
        },
        info: "_START_-_END_ de _TOTAL_",
        infoFiltered: "",
        emptyTable: "Aún no hay datos",
        infoEmpty: "0 de 0"

      },
      dom: 'Bfrtip',
      buttons: [
          {   extend: "csv",
              className: "btn-default btn Ripple-parent float-right btn-sm",
              text: "Exportar",
              filename: "Resumen Ventas"
          }
      ]
  
    });

    $(document).on("click", 'tr', function(event) {
      event.preventDefault();
      tt.setState( {
        item: {
          objectId: $(this).data("id"),
          name: $(this).data("name")
        }
      },() => {  

        tt.setState( {
          modal16: true
        })
      });
    });
    sessionStorage.setItem('previous-page', this.props.location.pathname) 

  }

  

  state = {
    sid: "",
    token: "",
    fromDate: moment().startOf('month').format('YYYY/MM/DD'),
    endDate: moment().endOf('month').format('YYYY/MM/DD'),
    uid: "",
    modal16: false,
    modal1: false,
    showexport: true,
    item: {
      objectId: "",
      name: ""
    }
  };

  loadContent() {
    table.clear();
    let p1 = new Promise((resolve, reject) => {
        var doc = {
          "startDate": moment(this.state.fromDate).startOf("day").format(),
          "endDate": moment(this.state.endDate).endOf("day").format(),
          "state": [1,2]
          };
        const pp = sales(doc);
        resolve(pp);
    });
    p1.then( (ts) => {

        this.tableRefresh(ts.data)
        this.setState({
            SpinnerHide: true
        });

    });
  }

  tableRefresh(objs) {

    if (objs== null)
      return;

    var oo = []
    
    objs.map((area) => {
      var obj2 = {
        id  : area.id,
        date: moment(area.created_at).format("DD/MM/YYYY HH:mm"),
        transaction_number: area.transaction_number,
        client: area.client,
        amount: new Intl.NumberFormat(area.locale, { style: 'currency', currency: area.code }).format((area.payment_type === "0" ? area.amount : area.amount*-1) ),
        exchange_rate: area.exchange_rate,
        total:  new Intl.NumberFormat(area.locale, { style: 'currency', currency: "EUR" }).format( (area.payment_type === "0" ? (area.amount*area.exchange_rate) : (area.amount*area.exchange_rate)*-1) ) ,
        payment_method: config.DICTIONARY.PAYMENT_METHOD[area.payment_method],
        gateway: config.DICTIONARY.GATEWAY[area.gateway],
        state: config.DICTIONARY.STATE[area.state],
      };
      oo.push(JSON.parse(JSON.stringify(eval(obj2))));

    });
    table.rows.add(oo).draw();
  }

  getPickerFromValue = (value) => {
    this.state.fromDate = moment(value)
  }  
  getPickerEndValue = (value) => {
      this.state.endDate = moment(value)
  }  

  clickToGo = event => {
    this.setState({
        SpinnerHide: false
    });

    this.loadContent();
  }
  exportToGo = event => {
    
  }

  render() {
    let search = window.location.search;

    return (
        <div>
            <MDBContainer>
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>Informes</MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>Ventas</MDBBreadcrumbItem>
              </MDBBreadcrumb>        

              <MDBCard className="card-transparent">
                <MDBCardBody className="table-act">

                  <MDBRow   className="zindexTo9 rowFilter">
                      <MDBCol lg="3" sm="12">
                      <label style={{float:"left", paddingRight:"10px", position: "relative",fontWeight: "400", fontSize:"15px"}}>De fecha:</label>
                          <MDBDatePicker className="datepicker dpm date_dashboard" format= "DD/MM/YYYY" getValue={this.getPickerFromValue} valueDefault={this.state.fromDate} locale={moment.locale('es')}  />                
                          <MDBIcon className="icalendar-alt" far icon="calendar-alt"/>
                      </MDBCol>
                      <MDBCol lg="3" sm="12">
                          <label style={{float:"left", paddingRight:"10px", position: "relative",fontWeight: "400", fontSize:"15px"}}>A fecha:</label>
                          <MDBDatePicker className="datepicker dpm date_dashboard" format= "DD/MM/YYYY" getValue={this.getPickerEndValue}  valueDefault={this.state.endDate} locale={moment.locale('es')}  />                
                          <MDBIcon far className="icalendar-alt" icon="calendar-alt"/>
                      </MDBCol>
                      <MDBCol className="zindexTo9" lg="4" sm="12">
                          <MDBBtn onClick={this.clickToGo} size="sm" className="float-left btn-confirm-1 " >Enviar</MDBBtn>
                    

                      </MDBCol>
                  </MDBRow>

                  <table ref="main" />

                </MDBCardBody>
              </MDBCard> 
    

            </MDBContainer>
        </div>

    );
  }
}
 