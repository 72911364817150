var env2 = require("../../config/env.js")


  export const sales = async( params ) =>{

    const response = await fetch((env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/api/v1/reports/sales', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    });
 
   return response.json()
}
