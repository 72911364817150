import React, { Component } from "react";

import { MDBContainer, MDBCardBody,  MDBRow, MDBCol, MDBModal, MDBAlert, MDBIcon,
  MDBModalFooter, MDBBtn} from "mdbreact";
import {update, get} from "../Payments/functions";

const moment = require("moment")
const errorColor = {
  "100": ["info","spinner"],
  "200": ["success","check"],
  "300": ["warning","exclamation"],
  "500": ["danger","exclamation-triangle"]
};

export default class Confirmation extends Component {

  toggle = nr => () => {
      document.location.href = "/pagos";  
  }

  componentWillUnmount() {

  }

  componentDidMount() {

    if ( sessionStorage.getItem('transaction-id') !== "undefined"  ) {
      if (this.props.match.params.success === "ok") {
        let myPromise = new Promise((resolve, reject) => {
          const pp = get(sessionStorage.getItem('transaction-id'));
          resolve(pp);
        });
        myPromise.then( (results) => {
          console.log("RESULTS: ", results)
        });
        myPromise = new Promise((resolve, reject) => {
          const pp = update(sessionStorage.getItem('transaction-id'), {state:1});
          resolve(pp);
        });
        myPromise.then( (results) => {
          this.setState({
            modal200: true,
            message: {
              color: errorColor[200][0],
              icon: errorColor[200][1],
              code: 200,
              description:"La operación se ha realizado correctamente.",
              solution: ""
            }
          })
        });
      } else if (this.props.match.params.success === "ko") {
        let myPromise = new Promise((resolve, reject) => {
          const pp = update(sessionStorage.getItem('transaction-id'), {state:2});
          resolve(pp);
        });
        myPromise.then( (results) => {

          this.setState({
            modal200: true,
            message: {
              color: errorColor[500][0],
              icon: errorColor[500][1],
              code: "500",
              description: "No se ha realizado la operación. Vuelva a intentarlo de nuevo.",
              solution: "Debe realizar el proceso de nuevo."
            }
          })
        });
      } else if (this.props.match.params.success === "transaction") {
        let myPromise = new Promise((resolve, reject) => {
          const pp = get(sessionStorage.getItem('transaction-id'));
          resolve(pp);
        });
        myPromise.then( (results) => {
        });
      }

      sessionStorage.setItem('transaction-id', undefined) 
    
    } else {
      this.setState({
        modal200: true,
        message: {
          color: errorColor[500][0],
          icon: errorColor[500][1],
          code: "500",
          description: "Acceso no permitido",
          solution: "Vuelva al inicio."
        }
      })
}
    
    sessionStorage.setItem('previous-page', this.props.location.pathname) 

  }
  



  state = {
    moment: moment(),
    modal404: false,
    modal200: false,
    message: {
      description: "",
      solution: ""
    }
    
  };






  render() {
    return (
        <div>
            <MDBContainer>
              <MDBModal isOpen={this.state.modal200}>
                <MDBAlert  color={this.state.message.color}  >
                  <MDBBtn className="btnX" onClick={this.toggle(90)}>X</MDBBtn>
                  <h2 style={{paddingLeft: "20px", paddingTop: "15px"}}><MDBIcon icon={this.state.message.icon} />&nbsp;&nbsp;</h2>
                  <hr />
                  <p>{this.state.message.description}</p>
                  <p>{this.state.message.solution}</p>
                </MDBAlert>
                </MDBModal> 
            </MDBContainer>
        </div>
    );
  }
}
 