import React, { Component } from "react";

import { MDBContainer, MDBCardBody, MDBCard, MDBCardHeader,MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBInput, 
  MDBModalHeader, MDBRow, MDBCol,
  MDBAlert,
  toast, ToastContainer,MDBBreadcrumb,MDBBreadcrumbItem
  } from "mdbreact";

  const $ = require('jquery');
  $.DataTable = require('datatables.net');
  require("datatables.net-responsive");

  var table = null;

  const columns= [
    {
      title: 'id',
      data: 'objectId'
    },
    {
        title: 'Nombre',
        data: 'name'
    },
    {
        title: 'Responsable',
        data: 'responsible'
    }
    ];
  
export default class Cliente extends Component {

  componentWillUnmount() {
    $(document).unbind();
  }

  componentDidMount() {
    var tt = this;
    table = $(this.refs.main).DataTable({
      columns,
      data: [],
      ordering: false,
      processing: true,
      select: true,
      createdRow: function( row, data ) {
        $( row ).attr('data-id', data.objectId);
        $( row ).attr('data-name', data.name);
      }, 
      columnDefs: [
        {
            targets: [ 0 ],
            visible: false,
            searchable: false
        }
      ],
      lengthChange: false,
      language: {
        search: "Buscar",
        paginate: {
          previous: "&lang;",
          next: "&rang;"
        },
        info: "_START_-_END_ de _TOTAL_",
        infoFiltered: "",
        emptyTable: "Aún no hay datos",
        infoEmpty: "0 de 0"

      }
  
    });

    $(document).on("click", 'tr', function(event) {
      event.preventDefault();
      tt.setState( {
        item: {
          objectId: $(this).data("id"),
          name: $(this).data("name")
        }
      },() => {  

        tt.setState( {
          modal16: true
        })
      });
    });

  }

  state = {
    sid: "",
    token: "",
    uid: "",
    modal16: false,
    modal1: false,
    item: {
      objectId: "",
      name: ""
    }
  };

  clearForm = () => {
    this.setState({
      item: {
        objectId: "",
        name: ""
      }
    });

  }

  toggle = nr => () => {
    if (nr==16)
      this.clearForm();

    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

  tableRefresh(objs) {

    if (objs== null)
      return;

    var oo = []
    
    objs.map((area) => {
      var obj2 = {
        objectId  : area.objectId,
        name: area.name,
        responsible: area.managers,
      };
      oo.push(JSON.parse(JSON.stringify(eval(obj2))));

    });
    table.rows.add(oo).draw();
  }
  
  submitHandler = event => {
    event.preventDefault();    
  };
  
  changeHandler = event => {
    const {name, value} = event.target;
    this.state.item[name] = value;
  };

  destroy = () => {
    var tt = this;
  }

  render() {
    let search = window.location.search;

    return (
        <div>
            <MDBContainer>
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>Clientes</MDBBreadcrumbItem>
              </MDBBreadcrumb>        

              <MDBCard className="card-transparent">
                <MDBCardBody className="table-act">
                  <MDBRow className="rowFilter">
                    <MDBCol  className="zindexTo9" size="2">
                      <MDBBtn onClick={  this.toggle(16)} size="sm"  className="float-left btn-confirm-1" >Nuevo</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                  <table ref="main" />

                </MDBCardBody>
              </MDBCard> 
 
              <MDBModal isOpen={this.state.modal16} toggle={this.toggle(16)} backdrop={false}>
                <form onSubmit={this.submitHandler}>
                  <MDBModalHeader className="modal-header-form">
                  {this.state.item.objectId === "" && 
                    ("Añadir cliente")
                  }
                  {this.state.item.objectId !== "" && 
                    ("Editar cliente")
                  }
                    
                  </MDBModalHeader>
                  <MDBModalBody>
                      <MDBRow>
                        <MDBCol>
                        <div class="form_field_label">Nombre</div>

                          <MDBInput
                            type="text"
                            validate
                            name="name"
                            placeHolder="Nombre del cliente"
                            size="20"
                            error="wrong"
                            required
                            onChange={this.changeHandler}
                            valueDefault={this.state.item.name}
                            success="right"
                            />
                        </MDBCol>
                      </MDBRow>

                  </MDBModalBody>
                  <MDBModalFooter>
                  <MDBBtn disabled={this.state.item.objectId===""} className="btn-destroy-1" onClick={this.toggle(1)}>ELIMINAR</MDBBtn>
                  <MDBBtn className="btn-cancel-1" onClick={this.toggle(16)}>CERRAR</MDBBtn>
                  <MDBBtn type="submit" className="btn-confirm-1">GUARDAR</MDBBtn>
                  </MDBModalFooter>
                </form>
            </MDBModal>

            <MDBModal className="kabit-alert-warning" isOpen={this.state.modal1} toggle={this.toggle(1)} frame position="top">
              <ToastContainer 
                className= "toast-container"
                position="top-right"  
                hideProgressBar={false}
                newestOnTop={true}
                autoClose={5000}
              />
              <MDBContainer color="warning">
                <MDBAlert color="warning">
                  <MDBRow>
                    <MDBCol>
                      <h4 className="alert-heading">¿Desea eliminar el cliente?</h4>
                      <p>Se requiere confirmación</p>
                    </MDBCol>
                    <MDBCol>
                      <MDBBtn className="btn-destroy-1" onClick={this.destroy}>Eliminar</MDBBtn>
                      <MDBBtn className="btn-cancel-1" onClick={this.toggle(1)}>CERRAR</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </MDBAlert>     
              </MDBContainer>
            </MDBModal>       

            </MDBContainer>
        </div>

    );
  }
}
 