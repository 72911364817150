import React, { Component } from "react";

import { MDBContainer ,MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBInput, 
  MDBModalHeader, MDBBreadcrumb, MDBBreadcrumbItem
} from "mdbreact";

const $ = require('jquery');


export default class User extends Component {

  componentWillUnmount() {
    $(document).unbind();
  }

  componentDidMount() {
   
  }

  state = {
    sid: "",
    token: "",
    modal16: true,
    uid: "",
    commerces: [],
  };
  
  toggle = nr => () => {
    if (nr==16)
      this.clearForm();

    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

  goBack = nr => () => {
    window.location.href = "/";
  }
 
  submitHandler = event => {
    event.preventDefault();
    sessionStorage.setItem('token', "");
    window.location.href = "/";
  };
  
  changeHandler = event => {

  };

  render() {
    let search = window.location.search;

    return (
        <div>
            <MDBContainer>
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>Usuarios</MDBBreadcrumbItem>
              </MDBBreadcrumb>        
      
              <MDBModal isOpen={this.state.modal16} backdrop={false}>
                <form onSubmit={this.submitHandler}>
                  <MDBModalHeader className="modal-header-form">
                    Cerrar Sesión  
                  </MDBModalHeader>
                  <MDBModalBody>
                    ¿Deseas cerrar la sesión?  
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn className="btn-cancel-1" onClick={this.goBack()}>No</MDBBtn>
                    <MDBBtn type="submit" className="btn-confirm-1">Sí</MDBBtn>
                  </MDBModalFooter>
                </form>
            </MDBModal>

             

            </MDBContainer>
        </div>

    );
  }
}
 