import React, { Component } from "react";

import { MDBContainer, MDBCardBody, MDBCard, MDBCardHeader,MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBInput, 
  MDBModalHeader, MDBRow, MDBCol,
  MDBAlert, MDBSelect,
  toast, ToastContainer,MDBBreadcrumb, MDBBreadcrumbItem, MDBNavLink,MDBIcon
} from "mdbreact";

import {list, create, getById, update, updatepass, destroy} from "./functions";
import {list as listcommerces} from "../Commerces/functions";
var roles = [{value: 0, text: "Usuario Comercio"},{ value:1, text: "Administrador Comercio"}]
const $ = require('jquery');
$.DataTable = require('datatables.net');
require("datatables.net-responsive");
const moment = require("moment")
const errorColor = {
  "200": ["success","check"],
  "300": ["warning","exclamation"],
  "23505": ["warning","exclamation"],
  "500": ["danger","exclamation-triangle"]
};
var table = null;

const columns= [
  {
    title: 'id',
    data: 'id'
  },
  {
    title: 'Nombre',
    data: 'name'
  },
  {
    title: 'Usuario',
    data: 'username'
  },
  {
    title: 'Email',
    data: 'email'
  },
  {
    title: 'Comercio',
    data: 'commercename'
  }
];


export default class User extends Component {

  componentWillUnmount() {
    $(document).unbind();
  }

  componentDidMount() {


    var tt = this;
    table = $(this.refs.main).DataTable({
      columns,
      data: [],
      ordering: false,
      processing: true,
      select: true,
      createdRow: function( row, data ) {
        $( row ).attr('data-id', data.id);
        $( row ).attr('data-name', data.name);
      }, 
      columnDefs: [
        {
            targets: [ 0 ],
            visible: false,
            searchable: false
        }
      ],
      lengthChange: false,
      language: {
        search: "Buscar",
        paginate: {
          previous: "&lang;",
          next: "&rang;"
        },
        info: "_START_-_END_ de _TOTAL_",
        infoFiltered: "",
        emptyTable: "Aún no hay datos",
        infoEmpty: "0 de 0"

      }
  
    });

    let myPromise = new Promise((resolve, reject) => {

      const pp = list({active: 1});
      resolve(pp);
    });
    
    myPromise.then( (results) => {
      this.tableRefresh(results.data);
    });

     myPromise = new Promise((resolve, reject) => {
      const pp = listcommerces();
      resolve(pp);
    });
    myPromise.then( (results) => {
      tt.setState({
        commerces: results.data.map(object => ({text: object.name, value: object.id})),
      })
    });
    
    $(document).on("click", 'tr', function(event) {

      event.preventDefault();
      let myPromise2 = new Promise((resolve, reject) => {
        const pp = getById( $(this).data("id"));
        resolve(pp);
      });
  
      myPromise2.then( (results) => {
        if (results.data.length !==0) {
          tt.setState({
            item: {
              id: results.data[0].id,
              name: results.data[0].name,
              email: results.data[0].email,
              username: results.data[0].username,
              commerce_id: results.data[0].commerce_id,
              commercename: tt.state.commerces.filter(function(item) { return item.value === results.data[0].commerce_id })[0]["text"],
              role: results.data[0].role,
              rolename: roles.filter(function(item) { return item.value === results.data[0].role })[0]["text"]
              
            }
          },() => {  
            tt.setState( {
              modal16: true
            });
          })
  
        }
      });
    });

        sessionStorage.setItem('previous-page', this.props.location.pathname) 

  }



  state = {
    sid: "",
    token: "",
    uid: "",
    modal90: false,
    modal16: false,
    modal1: false,
    commerces: [],
    item: {
      id: "",
      name: "",
      email: "",
      commerce_id: 0,
      role: 3
    },
    message: {
      icon: "",
      code: "",
      description: "",
      solution: ""
    }  
  };


  clearForm = () => {
    this.setState({
      item: {
        id: "",
        name: ""
      }
    });

  }

  toggle = nr => () => {
    if (nr==16)
      this.clearForm();
    if (nr == 90 && this.state.modal90 == true) {
      if (this.state.message.code === 200) {
        this.setState({
          modal16: false
        })
      }
    }
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

  tableRefresh(objs) {
    
    if (objs== null)
      return;

    var oo = []
    
    objs.map((area) => {
      var obj2 = {
        id  : area.id,
        name: area.name,
        username: area.username,
        email: area.email,
        commercename: area.commercename
      };
      oo.push(JSON.parse(JSON.stringify(eval(obj2))));

    });
    table.rows.add(oo).draw();
  }

  
  submitHandler = event => {
    event.preventDefault();
    var tt = this;

    var obj = {
      id: this.state.item.id,
      name: this.state.item.name,
      email: this.state.item.email,
      username: this.state.item.username,
      password: this.state.item.password,
      repeatpassword: this.state.item.repeatpassword,
      commerce_id: this.state.item.commerce_id,
      role: this.state.item.role
    }

    if (this.state.item.id === "") {
      let myPromise = new Promise((resolve, reject) => {
        const pp = create(obj);
        resolve(pp);
      });
      myPromise.then( (results) => {

        if (results.code === 200)  {
          this.setState({
            modal90: true,
            message: {
              color: errorColor[results.code][0],
              icon: errorColor[results.code][1],
              code: results.code,
              description: "La operación se ha realizado correctamente",
              solution: results.solution
            }
          })
  

        } else {
          this.setState({
            modal90: true,
            message: {
              color: errorColor[results.code][0],
              icon: errorColor[results.code][1],
              code: results.code,
              description:results.description,
              solution: results.solution
            }
          })
  
        }


      });
    } else {

      let myPromise = new Promise((resolve, reject) => {
        const pp = update(this.state.item.id, obj);
        resolve(pp);
      });
      myPromise.then( (results) => {
        window.location.href = "users"
      });    

    }

    

  };
  submitPassword
  = event => {
    event.preventDefault();
    var tt = this;

    var obj = {
      id: this.state.item.id,
      password: this.state.item.password,
    }

    

    let myPromise = new Promise((resolve, reject) => {
      const pp = updatepass(this.state.item.id, obj);
      resolve(pp);
    });
    myPromise.then( (results) => {
      window.location.reload(false);
    }); 
    

  };

  changeHandler = event => {
    const {name, value} = event.target;
    this.state.item[name] = value;

  };
  onChangeCommerce = event => {
    this.state.item.commerce_id = event[0]
  }
  onChangeRole= event => {
    this.state.item.role = event[0]
  }
  destroy = () => {
    var tt = this;

    let userPromise = new Promise((resolve) => {
      const pp = destroy(this.state.item.id);
      resolve(pp);
    });
    userPromise.then( (result) => {
      window.location.reload(false);
      console.log("RES: ", result)
    });

  }

  render() {
    let search = window.location.search;

    return (
        <div>
            <MDBContainer>
            <MDBBreadcrumb>
          <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>Usuarios</MDBBreadcrumbItem>
        </MDBBreadcrumb>        

              <MDBCard className="card-transparent">
                <MDBCardBody className="table-act">
                  <MDBRow className="rowFilter">
                    <MDBCol  className="zindexTo9" size="2">
                      <MDBBtn onClick={  this.toggle(16)} size="sm"  className="float-left btn-confirm-1" >Nuevo</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                  <table ref="main" />

                </MDBCardBody>
              </MDBCard> 
 
              <MDBModal isOpen={this.state.modal16} toggle={this.toggle(16)} backdrop={false}>
                <form onSubmit={this.submitHandler}>
                  <MDBModalHeader className="modal-header-form">
                  {this.state.item.id === "" && 
                    ("Añadir usuario")
                  }
                  {this.state.item.id !== "" && 
                    ("Editar usuario")
                  }
                  </MDBModalHeader>
                  <MDBModalBody>
                    <MDBRow>
                      <MDBCol>
                        <MDBRow>
                          <MDBCol className="col-lg-3">
                            <div class="form_field_label">Nombre</div>
                          </MDBCol>
                          <MDBCol>
                            <MDBInput
                            type="text"
                            validate
                            name="name"
                            placeHolder="Nombre del usuario"
                            size="20"
                            error="wrong"
                            required
                            onChange={this.changeHandler}
                            valueDefault={this.state.item.name}
                            success="right"
                            />
                          </MDBCol>
                        </MDBRow>                        
                        
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <MDBRow>
                          <MDBCol className="col-lg-3">
                            <div class="form_field_label">Email</div>
                          </MDBCol>
                          <MDBCol>
                            <MDBInput
                            type="text"
                            validate
                            name="email"
                            placeHolder="Dirección de correo electrónico"
                            size="20"
                            error="wrong"
                            required
                            onChange={this.changeHandler}
                            valueDefault={this.state.item.email}
                            success="right"
                            />
                          </MDBCol>
                        </MDBRow>                        
                        
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <MDBRow>
                          <MDBCol className="col-lg-3">
                            <div class="form_field_label">Usuario</div>
                          </MDBCol>
                          <MDBCol>
                            <MDBInput
                            type="text"
                            validate
                            name="username"
                            placeHolder=""
                            size="20"
                            error="wrong"
                            required
                            onChange={this.changeHandler}
                            valueDefault={this.state.item.username}
                            success="right"
                            />
                          </MDBCol> 
                        </MDBRow>                        
                      </MDBCol>
                    </MDBRow>
                    {this.state.item.id !== "" && (<><MDBRow style={{ padding: "0 20px 20px 0px"}}><MDBCol><a onClick={this.toggle(2)}  className="form_field_label" style={{color: "blue", fontSize: "12px"}}>Restablecer contraseña</a></MDBCol></MDBRow></>)}
                    {this.state.item.id === "" && 
                    (<>
                      <MDBRow>
                        <MDBCol>
                          <MDBRow>
                            <MDBCol className="col-lg-3">
                              <div class="form_field_label">Contraseña</div>
                            </MDBCol>
                            <MDBCol>
                              <MDBInput
                              type="password"
                              validate
                              name="password"
                              placeHolder=""
                              size="20"
                              error="wrong"
                              required
                              onChange={this.changeHandler}
                              valueDefault={this.state.item.password}
                              success="right"
                              />
                            </MDBCol>
                          </MDBRow>                        
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol>
                          <MDBRow>
                            <MDBCol className="col-lg-3">
                              <div class="form_field_label">Repite contraseña</div>
                            </MDBCol>
                            <MDBCol>
                              <MDBInput
                              type="password"
                              validate
                              name="repeatpassword"
                              placeHolder=""
                              size="20"
                              error="wrong"
                              required
                              onChange={this.changeHandler}
                              valueDefault={this.state.item.repeatpassword}
                              success="right"
                              />
                            </MDBCol>
                          </MDBRow>                        
                        </MDBCol>
                      </MDBRow> 
                    </>)}                  
                    <MDBRow>
                      <MDBCol>
                        <MDBRow>
                          <MDBCol className="col-lg-3">
                            <div class="form_field_label">Rol</div>
                          </MDBCol>
                          <MDBCol>
                            <MDBSelect
                              options= {roles}
                              getValue={this.onChangeRole}
                              selected= {this.state.item.rolename}
                            />
                          </MDBCol>
                        </MDBRow>                        
                      </MDBCol>
                      <MDBCol>
                        <MDBRow>
                          <MDBCol className="col-lg-3">
                            <div class="form_field_label">Comercio</div>
                          </MDBCol>
                          <MDBCol>
                            <MDBSelect
                              options= {this.state.commerces}
                              getValue={this.onChangeCommerce}
                              selected= {this.state.item.commercename}
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBModalBody>
                  <MDBModalFooter>
                  <MDBBtn disabled={this.state.item.id===""} className="btn-destroy-1" onClick={this.toggle(1)}>ELIMINAR</MDBBtn>
                  <MDBBtn className="btn-cancel-1" onClick={this.toggle(16)}>CERRAR</MDBBtn>
                  <MDBBtn type="submit" className="btn-confirm-1">GUARDAR</MDBBtn>
                  </MDBModalFooter>
                </form>
            </MDBModal>

            <MDBModal size="sm" isOpen={this.state.modal2} toggle={this.toggle(2)} >
              <form onSubmit={this.submitPassword}>

              <MDBModalHeader className="modal-header-form">
                Restablecer contraseña
              </MDBModalHeader>
              <MDBModalBody>
                <MDBContainer>
                <MDBRow>
                  <MDBCol>
                    <MDBRow>
                      <MDBCol className="col-lg-3">
                        <div class="form_field_label">Contraseña</div>
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                        type="password"
                        validate
                        name="password"
                        placeHolder=""
                        size="20"
                        error="wrong"
                        required
                        onChange={this.changeHandler}
                        valueDefault={this.state.item.password}
                        success="right"
                        />
                      </MDBCol>
                    </MDBRow>                        
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBRow>
                      <MDBCol className="col-lg-3">
                        <div class="form_field_label">Repite contraseña</div>
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                        type="password"
                        validate
                        name="repeatpassword"
                        placeHolder=""
                        size="20"
                        error="wrong"
                        required
                        onChange={this.changeHandler}
                        valueDefault={this.state.item.repeatpassword}
                        success="right"
                        />
                      </MDBCol>
                    </MDBRow>                        
                  </MDBCol>
                </MDBRow> 
                </MDBContainer>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn className="btn-cancel-1" onClick={this.toggle(2)}>CERRAR</MDBBtn>
                <MDBBtn type="submit" className="btn-confirm-1">ACTUALIZAR</MDBBtn>
              </MDBModalFooter>
              </form>
            </MDBModal>      

            <MDBModal className="kabit-alert-warning" isOpen={this.state.modal1} toggle={this.toggle(1)} frame position="top">
              <ToastContainer 
                className= "toast-container"
                position="top-right"  
                hideProgressBar={false}
                newestOnTop={true}
                autoClose={5000}
              />
              <MDBContainer color="warning">
                <MDBAlert color="warning">
                  <MDBRow>
                    <MDBCol>
                      <h4 className="alert-heading">¿Desea eliminar el usuario?</h4>
                      <p>Se requiere confirmación</p>
                    </MDBCol>
                    <MDBCol>
                      <MDBBtn className="btn-destroy-1" onClick={this.destroy}>Eliminar</MDBBtn>
                      <MDBBtn className="btn-cancel-1" onClick={this.toggle(1)}>CERRAR</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </MDBAlert>     
              </MDBContainer>
            </MDBModal>       
            <MDBModal isOpen={this.state.modal90} backdrop={false}  toggle={this.toggle(90)}>
              <MDBAlert  color={this.state.message.color}  >
                <MDBBtn className="btnX" onClick={this.toggle(90)}>X</MDBBtn>
                <h2 style={{paddingLeft: "20px", paddingTop: "15px"}}><MDBIcon icon={this.state.message.icon} />&nbsp;&nbsp;</h2>
                <hr />
                <p>{this.state.message.description}</p>
                <p>{this.state.message.solution}</p>
              </MDBAlert>
            </MDBModal>

            </MDBContainer>
        </div>

    );
  }
}
 