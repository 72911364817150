// config.js
module.exports = {
  API_SERVER_NAME_DEVELOPMENT: "",
  TPV_URL_DEVELOPMENT: "" ,
  TPV_SERVER_NAME_DEVELOPMENT_OK: "" ,
  TPV_SERVER_NAME_DEVELOPMENT_KO: "" ,
  TPV_MERCHANT_KEY_DEVELOPMENT: "",

  PG_URL_DEVELOPMENT: "" ,
  PG_SERVER_NAME_DEVELOPMENT_OK: "" ,
  PG_SERVER_NAME_DEVELOPMENT_KO: "" ,
  PG_MERCHANT_KEY_DEVELOPMENT: "",

  API_SERVER_NAME_PRODUCTION: "https://api.gestioncomercios.com",
  TPV_URL_PRODUCTION: "https://sis-t.redsys.es:25443/sis/realizarPago",
  TPV_SERVER_NAME_PRODUCTION_OK: "https://www.gestioncomercios.com/confirmacion/ok",
  TPV_SERVER_NAME_PRODUCTION_KO: "https://www.gestioncomercios.com/confirmacion/ko",
  TPV_MERCHANT_KEY_PRODUCTION: "sq7HjrUOBfKmC576ILgskD5srU870gJ7",


  PG_URL_PRODUCTION: "https://sis-t.redsys.es/sis/rest/trataPeticionREST",
  PG_SERVER_NAME_PRODUCTION: "https://api.gestioncomercios.com/confirmacion",
  PG_MERCHANT_KEY_PRODUCTION: "7B3m7W4XLy6QAADFOVKG1UYAtxETEkvK ",

  API_SERVER_NAME_TPV: "https://www.gestioncomercios.com",
  TPV_MERCHANT_CODE: "355421330",
  TPV_TERMINAL_CODE: "1",

  PG_MERCHANT_CODE: "355887167",
  PG_TERMINAL_CODE: "1",


  API_VERSION: "1"
};
