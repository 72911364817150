import axios from 'axios';

var env2 = require("../../config/env.js")
export const list = async(params ) =>{

  const response = await fetch((env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/currencies', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    },
    body: JSON.stringify(params)
  });
  return response.json()
}
  
