import React, { Component } from "react";
import i18 from 'i18next';

import { MDBContainer, MDBCardBody, MDBCard,MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBInput, 
  MDBModalHeader, MDBRow, MDBCol, MDBAlert, ToastContainer,MDBBreadcrumb,MDBBreadcrumbItem,
  MDBSelect, MDBSelectInput, MDBSelectOption, MDBSelectOptions, MDBInputGroup,MDBIcon, MDBAutocomplete
  } from "mdbreact";
  import {create, update, get, list, paygold, listtransactions} from "../Payments/functions";
  import {sales} from "../Reports/functions";
  import {list as listcurrencies} from "../Currencies/functions";

  import CryptoJS  from "crypto-js";
  var env2 = require("../../config/env.js")

 // var CryptoJS = require("crypto-js");

  const config = require("../Utils/config")
  const moment = require("moment")
  var current_currency = {code: "EUR", rate: 1, locate: "es-CU", last_date_rate: ""};

  const errorColor = {
    "100": ["info","spinner"],
    "200": ["success","check"],
    "300": ["warning","exclamation"],
    "500": ["danger","exclamation-triangle"]
  };

  const $ = require('jquery');  

  $.DataTable = require('datatables.net');
  require("datatables.net-responsive");
  
  var table = null;
  var states = ["Pendiente", "Realizada", "Rechazada"];
  var transactionslist = []
  const columns= [
    {
      title: 'id',
      data: 'id'
    },
    {
      title: 'Fecha',
      data: 'createddate'
    },
    {
      title: 'Transacción',
      data: 'transaction_number'
    },
    {
      title: 'Pago',
      data: 'payment_method'
    },
    {
      title: 'Estado',
      data: 'state'
    },
    {
      title: 'Comercio',
      data: 'commercename'
    },
    {
      title: 'Cliente',
      data: 'client'
    },
    {
      title: 'Importe',
      data: 'amount'
    },
    {
      title: 'Cambio',
      data: 'exchange_rate'
    },
    {
      title: 'Total',
      data: 'total'
    }
    ];

  async function goredsys2(amount, transac, transaction_type) {

    //const transaction = (Math.floor(Math.random()*90000) + 10000).toString().concat("", transac)  
    var  tpvdata = {
        "DS_MERCHANT_AMOUNT": (parseFloat(amount).toFixed(2) * 100).toString(),
        "DS_MERCHANT_CURRENCY": "978",
        "DS_MERCHANT_MERCHANTCODE": env2.TPV_MERCHANT_CODE,
        "DS_MERCHANT_MERCHANTURL": (env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_TPV), 
        "DS_MERCHANT_ORDER": transac, 
        "DS_MERCHANT_TERMINAL": env2.TPV_TERMINAL_CODE,
        "DS_MERCHANT_TRANSACTIONTYPE": transaction_type,
        "DS_MERCHANT_URLOK": (env2.TPV_SERVER_NAME_DEVELOPMENT_OK || env2.TPV_SERVER_NAME_PRODUCTION_OK),
        "DS_MERCHANT_URLKO": (env2.TPV_SERVER_NAME_DEVELOPMENT_KO || env2.TPV_SERVER_NAME_PRODUCTION_KO)
      }; 

    // Base64 encoding of parameters
    var merchantWordArray = CryptoJS.enc.Utf8.parse(JSON.stringify(tpvdata));
    var merchantBase64 = merchantWordArray.toString(CryptoJS.enc.Base64);

    // Decode key
    var keyWordArray = CryptoJS.enc.Base64.parse( (env2.TPV_MERCHANT_KEY_DEVELOPMENT || env2.TPV_MERCHANT_KEY_PRODUCTION) );

    // Generate transaction key
    var iv = CryptoJS.enc.Hex.parse("0000000000000000");
    var cipher = CryptoJS.TripleDES.encrypt(tpvdata.DS_MERCHANT_ORDER, keyWordArray, {
      iv:iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding
    });

    // Sign
    var signature = CryptoJS.HmacSHA256(merchantBase64, cipher.ciphertext);
    var signatureBase64 = signature.toString(CryptoJS.enc.Base64);

    const response = {
      a:  merchantBase64,
      b: signatureBase64
    };

  return response;

  }

  function gopaygold(amount, transac, transaction_type, activation_for, tt) {

    //const transaction = (Math.floor(Math.random()*90000) + 10000).toString().concat("", transac)  

    let myPromise = new Promise((resolve, reject) => {
      var  tpvdata = {
        "DS_MERCHANT_AMOUNT": (parseFloat(amount).toFixed(2) * 100).toString(),
        "DS_MERCHANT_CURRENCY": "978",
        "DS_MERCHANT_CUSTOMER_MAIL": tt.state.item.email,
        "DS_MERCHANT_P2F_EXPIRYDATE": (1440 * parseInt(activation_for)).toString(),
        "DS_MERCHANT_MERCHANTCODE": env2.PG_MERCHANT_CODE, //"999008881"
        "DS_MERCHANT_MERCHANTURL": (env2.PG_SERVER_NAME_DEVELOPMENT || env2.PG_SERVER_NAME_PRODUCTION), 
        "DS_MERCHANT_ORDER": transac, 
        "DS_MERCHANT_TERMINAL": env2.PG_TERMINAL_CODE,
        "DS_MERCHANT_P2F_XMLDATA": "<nombreComprador></nombreComprador><direccionComprador></direccionComprador><textoLibre1>"+tt.state.item.service+"</textoLibre1><subjectMailCliente>Pago GestionComercios</subjectMailCliente>",
        "DS_MERCHANT_TRANSACTIONTYPE": "F"
      };

      console.log("TT: ", tpvdata)

      // Base64 encoding of parameters
      var merchantWordArray = CryptoJS.enc.Utf8.parse(JSON.stringify(tpvdata));
      var merchantBase64 = merchantWordArray.toString(CryptoJS.enc.Base64);

      // Decode key
      var keyWordArray = CryptoJS.enc.Base64.parse( (env2.PG_MERCHANT_KEY_DEVELOPMENT || env2.PG_MERCHANT_KEY_PRODUCTION) );


      // Generate transaction key
      var iv = CryptoJS.enc.Hex.parse("0000000000000000");
      var cipher = CryptoJS.TripleDES.encrypt(tpvdata.DS_MERCHANT_ORDER, keyWordArray, {
        iv:iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      });

      // Sign
      var signature = CryptoJS.HmacSHA256(merchantBase64, cipher.ciphertext);
      var signatureBase64 = signature.toString(CryptoJS.enc.Base64);

      const response = {
        a: merchantBase64,
        b: signatureBase64
      };
      resolve(response);

    });

    myPromise.then( (results) => {
      let myPromise2 = new Promise((resolve, reject) => {

        const pp = paygold(
          {
            Ds_MerchantParameters: results.a,
            Ds_SignatureVersion: "HMAC_SHA256_V1",
            Ds_Signature: results.b
        });

        console.log("PP: ", pp)
        resolve(pp);
      });

      myPromise2.then( (results) => {
        console.log("RESULTS: ", results)
        if (results.data.errorCode !== undefined)  {

          tt.setState({
            modal16: false,
            modal2: true,
            message: {
              color: errorColor[500][0],
              icon: errorColor[500][1],
              code: "500",
              description: "Problemas con la pasarela.",
              solution: "Pregunte al proveedor del servicio."
            }
          })        
        } else if (results.data.Ds_Signature !== undefined)  {
          tt.list();

          tt.setState({
            modal16: false,
            modal2: true,
            message: {
              color: errorColor[200][0],
              icon: errorColor[200][1],
              code: 200,
              description:"Se ha enviado la petición de pago aplazado al cliente.",
              solution: ""
            }
          })

        }
      });
    }, function (err){
      console.log("ERR: ", err)
    });

  }

export default class Payment extends Component {

  componentWillUnmount() {
    $(document).unbind();
  }

  list() {
    let myPromise = new Promise((resolve, reject) => {
      var doc = {
        "startDate": moment().startOf("day").format(),
        "endDate": moment().endOf("day").format(),
        "state": [1,2,3]
        };
      const pp = sales(doc);
      resolve(pp);
    });
    myPromise.then( (results) => {
      this.tableRefresh(results);
    });
  }

  loadingMe() {
    this.setState({
      modal9: true,
      message: {
        color: errorColor[100][0],
        icon: errorColor[100][1],
        code: "100",
        description: "Procesando. Espere un momento.",
        solution: ""
      }
    });
  }
  componentDidMount() {
    var tt = this;
    table = $(this.refs.main).DataTable({
      columns,
      data: [],
      ordering: false,
      processing: true,
      select: true,
      createdRow: function( row, data ) {
        $( row ).attr('data-id', data.id);
        $( row ).attr('data-name', data.name);
      }, 
      columnDefs: [
        {
            targets: [ 0 ],
            visible: false,
            searchable: false
        },
        {
          targets: [6,7,8],
          className: "text-right",
        }
      ],
      lengthChange: false,
      language: {
        search: "Buscar",
        paginate: {
          previous: "&lang;",
          next: "&rang;"
        },
        info: "_START_-_END_ de _TOTAL_",
        infoFiltered: "",
        emptyTable: "Aún no hay datos",
        infoEmpty: "0 de 0"

      }
  
    });
    this.list();

    let myPromise2 = new Promise((resolve, reject) => {
      const pp = listcurrencies();
      resolve(pp);
    });

    myPromise2.then( (results) => {
      this.setState({
        rawcurrencies:  results.data.map(object => ({id: object.id, code: object.code, locale: object.locale, name: object.name, rate: object.rate, last_date_rate: object.last_date_rate})),
        currencies: results.data.map(object => ({value: object.code, text: object.code}))
      })
    });
    myPromise2 = new Promise((resolve, reject) => {
      const pp = listtransactions({state: 1});
      resolve(pp);
    });

    myPromise2.then( (results) => {
      
      this.setState({
        transactionslist: results.data.map(object => (object.transaction_number)) 
      })
      transactionslist = results.data.map(object => (object.transaction_number)) 
    });

    $(document).on("click", 'tr', function(event) {
      event.preventDefault();
      let myPromise2 = new Promise((resolve, reject) => {
        const pp = get( $(this).data("id"));
        resolve(pp);
      });
  
      myPromise2.then( (results) => {
        if (results.data.length !==0) {
          tt.setState({
            item: {
              id: results.data[0].id,
              name: results.data[0].client,
              email: results.data[0].email_client,
              transaction: results.data[0].transaction_number,
              service: results.data[0].service,
              commerce: results.data[0].commercename,
              language: results.data[0].language,
              currency: results.data[0].code,
              amount: results.data[0].amount,
              gateway: results.data[0].gateway,
              value_date: results.data[0].value_date,
              state: results.data[0].state,
              payment_type: results.data[0].payment_type,
              payment_method: parseInt(results.data[0].payment_method)
            },
            rate: results.data[0].exchange_rate,
            rate_visible: true

          },() => {  
            tt.setState( {
              modal16: true
            });
          })
  
        }
      });
      
    });

    


  }
  
  tableRefresh(results) {
    
    table.clear();
    if (results === null)
      return;
    var oo = []
    results.data.map((object, i) => {
      var obj = {
        id  : object.id,
        createddate: moment(object.created_at).format("DD/MM/YYYY hh:mm"),
        transaction_number: object.transaction_number,
        payment_method: config.DICTIONARY.PAYMENT_METHOD[object.payment_method],
        state: states[object.state],
        commercename: object.commercename,
        client: object.client,
        amount: new Intl.NumberFormat(object.locale, { style: 'currency', currency: object.code }).format( (object.payment_type === "0" || object.payment_type === null ? object.amount : object.amount*-1)),
        exchange_rate: object.exchange_rate,
        total:  new Intl.NumberFormat(object.locale, { style: 'currency', currency: "EUR" }).format( (object.payment_type === "0" ? (object.amount*object.exchange_rate) : (object.amount*object.exchange_rate)*-1) ),
      };
      oo.push(JSON.parse(JSON.stringify(eval(obj))));

    });
    table.rows.add(oo).draw();
  }



  state = {
    moment: moment(),
    dsm: "",
    dss: "",
    sid: "",
    token: "",
    uid: "",
    language: 0,
    payment_method: 0,
    modal16: false,
    modal1: false,
    modal2: false,
    modal9: false,
    rate_visible: true,
    transactionslist: [],
    item: {
      id: 0,
      name: "",
      amount: 0,
      state: 0,
      transaction: "",
      currency: "EUR",
      currency_id: 0,
      exchange_rate:0,
      payment_type:0,
      user_id: 0,
      activation_for: 1
    },
    message: {
      icon: "",
      code: "",
      description: "",
      solution: ""
    },
    currencies: [],
    rawcurrencies: [],
    rate: 1,
    rated_amount: 0,
    products: [
      {name: "Asistente personal telefónico", value: "Asistente personal telefónico", amount: 25},
      {name: "Asesoramientos nutricionales", value: "Asesoramientos nutricionales", amount: 30}, 
      {name: "Asesoramientos psicológicos", value: "Asesoramientos psicológicos", amount: 30}, 
      {name: "Acompañamiento para el bienestar", value: "Acompañamiento para el bienestar", amount: 30}, 
      {name: "Servicios de proximidad", value: "Servicios de proximidad", amount: 25},
      {name: "Consultas integrales", value: "Consultas integrales", amount: 25},
      {name: "Servicios sociales", value: "Servicios sociales", amount: 30}
    ]
  };


  clearForm = (nr) => {
    current_currency = {code: "EUR", rate: 1, locate: "es-CU", last_date_rate: ""};
    this.onChangeCurrency(new Array("EUR"));
    if (nr === 16) {
      this.setState({
        item: {
          id: 0,
          name: "",
          amount: 0,
          state: 0,
          transaction: (Math.floor(Math.random()*9000) + 1000).toString().concat("",(Math.floor(Math.random()*9000000) + 1000000).toString()),
          currency: "EUR",
          exchange_rate:0,
          currency_id: 0,
          user_id: 0,
          value_date: "",
          payment_method: 0,
          activation_for: 1,
          service: "",
          

        },
        rate: 1,
        rate_visible: true,
        rated_amount: 0

      });
    } else {
      this.setState({
        item: {
          id: 0,
          name: "",
          amount: 0,
          state: 0,
          transaction: "",
          currency: "EUR",
          exchange_rate:0,
          currency_id: 0,
          user_id: 0,
          value_date: "",
          payment_method: 0,
          activation_for: 1
        },
        rate: 1,
        rate_visible: true,
        rated_amount: 0

      });

    }
    this.changeAmount()
  }

  toggle = nr => () => {
    if (nr===16 || nr === 12) {
      this.clearForm(nr);
      this.state.payment_method=0
    }

    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
    if (nr===2)
      window.history.pushState("", "", "/pagos");  
  }

  submitHandler = event => {
    event.preventDefault();
    if (this.state.item.service==="") {
      alert("Debe seleccionar un servicio")
      return;
    }
    if (this.state.item.amount===0) {
      alert("Debe introducir un importe")
      return;
    }
    this.loadingMe();
    var tt = this;
    // preparar últimos campos
    this.state.item.currency_id = this.state.rawcurrencies.filter(function(item) { return item.code === tt.state.item.currency })[0]["id"];
    this.state.item.exchange_rate = this.state.rate
    this.state.item.payment_type = (this.state.modal16 === true ? 0 : 3)
    this.state.item.payment_method = this.state.payment_method
    let myPromise = new Promise((resolve, reject) => {
      const pp = create(this.state.item);
      resolve(pp);
    });
    myPromise.then( (results) => {
      if (results.code === 200)  {

        sessionStorage.setItem('transaction-id', results.data[0].id);
        var orate = this.state.rate;
        try {
          orate = orate.replace(/,/g, '.') 
        } catch (e) {
          orate = this.state.rate;
        }
        this.setState({modal9: false})
        if (this.state.item.payment_method === 0 ) {
          let myPromise2 = new Promise((resolve, reject) => {
            const pp = goredsys2( this.state.item.amount * orate,  this.state.item.transaction, (this.state.modal16 === true ? "0" : "3") );

            resolve(pp);
          });
          myPromise2.then( (pp) => {
            tt.setState({
              dsm: pp.a,
              dss: pp.b  
            },() => {
              tt.forceUpdate();
              document.gototpv.submit();
            });

            if (results.code === 200) {
              this.setState({
                modal16: true
              });
            }
          },function (reason) {
            
            // rechazo
          })
        } else {
          gopaygold( this.state.item.amount * orate,  this.state.item.transaction, (this.state.modal16 === true ? "0" : "3"), this.state.item.activation_for, this );
        }
      } else {
        this.setState({
          modal2: true,
          message: {
            color: errorColor[results.code][0],
            icon: errorColor[results.code][1],
            code: results.code,
            description:results.description,
            solution: results.solution
          }
        })

      }

    });

  };

  onChangeCurrency = event => {

    //event = new Array("USD")
    if (event.length !== 0) {
      var obj =event[0]
      try {
        current_currency = this.state.rawcurrencies.filter(function(item) { return item.code === event[0] })[0];
        this.state.item.currency =  obj
        this.state.rate = Intl.NumberFormat().format((1/parseFloat(current_currency.rate) ))
        this.state.rated_amount = new Intl.NumberFormat(current_currency.locale, { style: 'currency', currency: "EUR"  }).format( this.state.item.amount * (1/parseFloat(current_currency.rate)) )
        this.state.rate_visible = false
        this.setState({
          moment: moment()
        })
      } catch(e) { }
    }
  }

  onChangeActivation = event => {

    //event = new Array("USD")
    if (event.length !== 0) {
      var obj =event[0]
      try {
        this.state.item.activation_for =  obj
        this.setState({
          moment: moment()
        })
      } catch(e) { }
    }
  }
  changeLanguage = nr => () => {
    this.setState({
      language: nr
    });
  }

  changePayment = nr => () => {
    this.setState({
      payment_method: nr,

    });
    this.state.item.payment_method = nr
  }
  onChangeProduct = event => {
    const tt = this
    //event = new Array("USD")
    if (event.length !== 0) {
      var obj =event[0]
      try {
        this.state.item.service =  obj

        this.state.products.forEach(function(key) {
          var value = key;
          if (key["name"] === obj) {
            tt.changeAmount({target:{name: "amount", value: key["amount"]}})

          }
          
        });
      } catch(e) { }
    }
  }
  changeHandler = event => {
    const {name, value} = event.target;
    this.state.item[name] = value;
  };

  changeAmount = event => {
    if (event === undefined) return;
    const {name, value} = event.target;
    this.state.item.amount  = value;
    var curre = this.state.item.currency
    current_currency = this.state.rawcurrencies.filter(function(item) { return item.code === curre; })[0];

    this.state.rate= Intl.NumberFormat().format((1/parseFloat(current_currency.rate) ))
    this.state.rated_amount= new Intl.NumberFormat(current_currency.locale, { style: 'currency', currency: "EUR"  }).format( this.state.item.amount * (1/parseFloat(current_currency.rate)) )
    this.state.rate_visible= false
    this.setState({
      moment: moment()
    })
  };

  destroy = () => {
    var tt = this;
  }

  render() {
    let search = window.location.search;
    let isRole = 0;
    return (
        <div>
            <MDBContainer>
              
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
                <MDBBreadcrumbItem >Pagos</MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>Hoy</MDBBreadcrumbItem>
              </MDBBreadcrumb>        

              <MDBCard className="card-transparent">
                <MDBCardBody className="table-act">
                  <MDBRow className="rowFilter">
                    <MDBCol  className="zindexTo9"  lg="2" xs="3">
                      <MDBBtn onClick={  this.toggle(16)} size="sm"  className="float-left btn-confirm-1" >Nuevo</MDBBtn>
                    </MDBCol>
                    <MDBCol  className="zindexTo9"  lg="2" xs="3">
                      <MDBBtn onClick={  this.toggle(12)} size="sm"  className="float-left btn-cancel-1 " >Devolución</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                  <table ref="main" />

                </MDBCardBody>
              </MDBCard> 
 
              <MDBModal isOpen={this.state.modal16} toggle={this.toggle(16)} backdrop={false}>
                <form onSubmit={this.submitHandler}>
                  <MDBModalHeader className="modal-header-form">
                    {(this.state.item.id==0 ? "Realizar pago" : "Detalles de la operación")} 
                  </MDBModalHeader>
                    <MDBModalBody>
                      <MDBRow>
                        <MDBCol>
                          <MDBRow className="no-gutters">
                            <MDBCol className="col-lg-3">
                              <div className="form_field_label">Nombre y apellidos</div>
                            </MDBCol>
                            <MDBCol >
                              <MDBInput
                              type="text"
                              validate
                              name="name"
                              disabled={this.state.item.id !== 0}
                              placeHolder="Nombre y apellidos"
                              size="20"
                              error="wrong"
                              required
                              onChange={this.changeHandler}
                              valueDefault={this.state.item.name}
                              success="right"
                              />
                            </MDBCol>
                          </MDBRow>

                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                      <MDBCol>
                          <MDBRow className="no-gutters">
                            <MDBCol className="col-lg-3">
                              <div className="form_field_label">Correo electrónico</div>
                            </MDBCol>
                            <MDBCol>
                              <MDBInput
                                type="text"
                                validate
                                name="email"
                                disabled={this.state.item.id !== 0}
                                placeHolder="Correo electrónico"
                                size="20"
                                error="wrong"
                                required
                                onChange={this.changeHandler}
                                valueDefault={this.state.item.email}
                                success="right"
                                />
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol>
                        <MDBRow className="no-gutters">
                            <MDBCol className="col-lg-3">
                              <div className="form_field_label">Número de transacción</div>
                            </MDBCol>
                            <MDBCol>
                              <MDBInput
                                type="text"
                                validate
                                name="transaction"
                                disabled="true"
                                placeHolder="Transacción número"
                                size="20"
                                error="wrong"
                                required
                                onChange={this.changeHandler}
                                valueDefault={this.state.item.transaction}
                                success="right"
                                />
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>                      
                      <MDBRow>
                        <MDBCol>
                          <MDBRow className="no-gutters">
                            <MDBCol className="col-lg-3">
                              <div className="form_field_label">Servicio / Producto</div>
                            </MDBCol>                          
                            <MDBCol>
                              {this.state.item.id !== 0 &&
                              (
                                <div className="md-form form-20">
                                  <div className="border1">
                                  {this.state.item.service}
                                  </div>
                                </div>
                              )}
                              {this.state.item.id === 0 &&
                              (<>
                                <MDBSelect
                                  disabled={this.state.item.id !== 0}
                                  style={{left: "-6px"}}
                                  options= {this.state.products}
                                  name="service"
                                  required
                                  selected= {this.state.item.service}
                                  getValue={this.onChangeProduct}
                                />
                               
                              </>)}
                            </MDBCol>                          

                          </MDBRow>  

                        </MDBCol>
                      </MDBRow>  
                      {isRole === 4 || isRole === 2 &&
                      (
                      <MDBRow>
                        <MDBCol>
                          <MDBRow className="no-gutters">
                            <MDBCol className="col-lg-3">
                              <div className="form_field_label">Comercio</div>
                            </MDBCol>                          
                            <MDBCol>
                              <MDBInput
                                type="text"
                                validate
                                name="commerce"
                                disabled={this.state.item.id !== 0}
                                placeHolder="Nombre del equipo"
                                size="20"
                                error="wrong"
                                required
                                onChange={this.changeHandler}
                                valueDefault={this.state.item.commerce}
                                success="right"
                                />
                            </MDBCol>                          
                          </MDBRow>  
                        </MDBCol>
                      </MDBRow>   
                      )}
                      <MDBRow>
                        <MDBCol size="5">
                          <MDBRow className="no-gutters">
                            <MDBCol className="col-lg-3">
                              <div className="form_field_label">Idioma</div>
                            </MDBCol>                          
                            <MDBCol>
                              <MDBInput disabled={this.state.item.id !== 0} gap onClick={this.changeLanguage(0)} checked={this.state.language===0 ? true : false} label="ES" type="radio"
                                id="language1" />
                              <MDBInput disabled={this.state.item.id !== 0} gap onClick={this.changeLanguage(1)} checked={this.state.language===1 ? true : false} label="EN" type="radio"
                                id="language2" />
                            </MDBCol>                          
                          </MDBRow>  
                        </MDBCol>
                        <MDBCol size="7">
                          <MDBRow className="no-gutters">
                            <MDBCol className="col-lg-4">
                              <div className="form_field_label">Forma de pago</div>
                            </MDBCol>                          
                            <MDBCol>
                              
                              <MDBInput disabled={this.state.item.id !== 0} gap onClick={this.changePayment(0)} checked={this.state.item.payment_method===0 ? true : false} label="Inmediato" type="radio"
                              id="payment1" />
                            <MDBInput disabled={this.state.item.id !== 0} gap onClick={this.changePayment(1)} checked={this.state.item.payment_method===1 ? true : false} label="aplazado" type="radio"
                              id="payment2" />
                            </MDBCol>                          
                          </MDBRow>                            
                        </MDBCol>
                      </MDBRow>   
                      <MDBContainer className="overflow_div" hidden={this.state.item.id ===0}></MDBContainer>
                      <MDBRow>
                        
                      
                        <MDBCol size="4">
                          <MDBRow className="no-gutters">
                            <MDBCol className="col-lg-4">
                              <div className="form_field_label">Importe</div>
                            </MDBCol>                          
                            <MDBCol>
                              <span class="amount">{this.state.item.amount}</span> EUR
                                
                            </MDBCol>                          
                          </MDBRow>                            
                        </MDBCol>
                        <MDBCol>
                          <MDBRow className="no-gutters" hidden={this.state.payment_method===0}>
                            <MDBCol className="col-lg-4">
                              <div className="form_field_label">Activa por </div>
                            </MDBCol>                          
                            <MDBCol >
                              <MDBSelect disabled={this.state.item.id !== 0} name="activation_for" getValue={this.onChangeActivation}>
                                <MDBSelectInput selected="1" />
                                <MDBSelectOptions>
                                  <MDBSelectOption disabled={this.state.item.id !== 0} value="1">1</MDBSelectOption>
                                  <MDBSelectOption disabled={this.state.item.id !== 0} value="2">2</MDBSelectOption>
                                  <MDBSelectOption disabled={this.state.item.id !== 0} value="3">3</MDBSelectOption>
                                  <MDBSelectOption disabled={this.state.item.id !== 0} value="4">4</MDBSelectOption>
                                  <MDBSelectOption disabled={this.state.item.id !== 0} value="5">5</MDBSelectOption>
                                </MDBSelectOptions>
                              </MDBSelect> 
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                      {this.state.item.id !== 0 &&
                        (<>
                          <div className="p-3 ">
                            <strong>Resultado:</strong><br/>
                            <strong>Fecha: </strong> { moment(this.state.item.value_date).format("DD/MM/YYYY hh:mm")}&nbsp;&nbsp;
                            <strong>Cambio:</strong> { new Intl.NumberFormat().format( this.state.rate ) }&nbsp;&nbsp;
                            <strong>Estado:</strong> {states[this.state.item.state]}
                          </div>
                          <br/>
                        </>

                        )}
                  </MDBModalBody>
                  <MDBModalFooter>
                  <MDBBtn className="btn-cancel-1" onClick={this.toggle(16)}>CERRAR</MDBBtn>
                  <MDBBtn hidden={this.state.item.id!==0}type="submit" className="btn-confirm-1">ENVIAR</MDBBtn>
  
                  </MDBModalFooter>
                </form>
                  <form name="gototpv" action= {(env2.TPV_URL_DEVELOPMENT || env2.TPV_URL_PRODUCTION)} method="POST">
                  <input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1" />
                  <input type="hidden" name="Ds_MerchantParameters" value={this.state.dsm} />
                  <input type="hidden" name="Ds_Signature" value={this.state.dss} />
                </form>
            </MDBModal>

            <MDBModal isOpen={this.state.modal12} toggle={this.toggle(12)} backdrop={false}>
                <form onSubmit={this.submitHandler}>
                  <MDBModalHeader className="modal-header-form">
                    Realizar devolución
                  </MDBModalHeader>
                  <MDBModalBody>
                      <MDBRow>
                        <MDBCol>
                          <MDBRow>
                            <MDBCol className="col-lg-3">
                              <div className="form_field_label">Número de transacción</div>
                            </MDBCol>
                            <MDBCol>
                              <MDBAutocomplete 
                                type="text"
                                validate
                                name="transaction"
                                disabled={this.state.item.id !== 0}
                                placeHolder="Número de transacción"
                                size={5}
                                error="wrong"
                                required
                                data={this.state.transactionslist}
                                getValue={this.changeTransactionHandler}
                                success="right"
                              />
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow>
                        <MDBCol>
                          <MDBRow>
                            <MDBCol className="col-lg-3">
                              <div className="form_field_label">Importe a devolver</div>
                            </MDBCol>
                            <MDBCol className="col-lg-3">
                              <MDBInputGroup
                                disabled={this.state.item.id !== 0}
                                name="amount"
                                id="amount"
                                onKeyUp={this.changeAmount}
                                onChange={this.changeAmount}
                                valueDefault={this.state.item.amount}
                                type="number"
                                containerClassName="mb-3"
                                prepend={
                                  <MDBSelect
                                    disabled={this.state.item.id !== 0}
                                    style={{left: "-6px"}}
                                    options= {this.state.currencies}
                                    name="currency"
                                    selected= {(this.state.item.currency===undefined) ? "EUR" : this.state.item.currency}
                                    getValue={this.onChangeCurrency}
                                  />
                                }/>
                                <span hidden={this.state.rate_visible} style={{fontSize: "0.7em", position: "absolute", top: "44px"}}> {this.state.rated_amount} ({this.state.rate})</span>
                            </MDBCol>
                          </MDBRow>                          

                        </MDBCol>
                      </MDBRow>                      
                      <MDBRow>
                        <MDBCol>
                          <MDBRow>
                            <MDBCol className="col-lg-3">
                              <div className="form_field_label">Servicio</div>
                            </MDBCol>
                            <MDBCol>
                              <MDBInput
                              type="text"
                              validate
                              disabled={this.state.item.id !== 0}
                              name="service"
                              placeHolder="Servicio"
                              size="20"
                              error="wrong"
                              required
                              onChange={this.changeHandler}
                              valueDefault={this.state.item.service}
                              success="right"
                            />
                            </MDBCol>
                          </MDBRow>                          
                        </MDBCol>
                      </MDBRow>                                                                                                                                                            
                  </MDBModalBody>
                  <MDBModalFooter>
                  <MDBBtn className="btn-cancel-1" onClick={this.toggle(12)}>CERRAR</MDBBtn>
                  <MDBBtn type="submit" className="btn-confirm-1">ENVIAR</MDBBtn>
                  </MDBModalFooter>
                </form>
                  <form name="gototpv" action= {(env2.TPV_URL_DEVELOPMENT || env2.TPV_URL_PRODUCTION)} method="POST">
                  <input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1" />
                  <input type="hidden" name="Ds_MerchantParameters" value={this.state.dsm} />
                  <input type="hidden" name="Ds_Signature" value={this.state.dss} />
                </form>
            </MDBModal>

            <MDBModal className="kabit-alert-warning" isOpen={this.state.modal1} toggle={this.toggle(1)} frame position="top">
              <ToastContainer 
                className= "toast-container"
                position="top-right"  
                hideProgressBar={false}
                newestOnTop={true}
                autoClose={5000}
              />
              <MDBContainer color="warning">
                <MDBAlert color="warning">
                  <MDBRow>
                    <MDBCol>
                      <h4 className="alert-heading">¿Desea eliminar el pago?</h4>
                      <p>Se requiere confirmación</p>
                    </MDBCol>
                    <MDBCol>
                      <MDBBtn className="btn-destroy-1" onClick={this.destroy}>Eliminar</MDBBtn>
                      <MDBBtn className="btn-cancel-1" onClick={this.toggle(1)}>CERRAR</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </MDBAlert>     
              </MDBContainer>
            </MDBModal>       
            <MDBModal isOpen={this.state.modal2} backdrop={false}  toggle={this.toggle(2)}>
              <MDBAlert  color={this.state.message.color}  >
                <MDBBtn className="btnX" onClick={this.toggle(2)}>X</MDBBtn>
                <h2 style={{paddingLeft: "20px", paddingTop: "15px"}}><MDBIcon icon={this.state.message.icon} />&nbsp;&nbsp;</h2>
                <hr />
                <p>{this.state.message.description}</p>
                <p>{this.state.message.solution}</p>
              </MDBAlert>
            </MDBModal>

            <MDBModal isOpen={this.state.modal9} backdrop={false} centered>

              <MDBAlert  color={this.state.message.color}  >
                <MDBBtn className="btnX" onClick={this.toggle(9)}>X</MDBBtn>
                <h2 style={{paddingLeft: "20px", paddingTop: "15px"}}><MDBIcon icon={this.state.message.icon} />&nbsp;&nbsp;</h2>
                <hr />
                <p>{this.state.message.description}</p>
                <p>{this.state.message.solution}</p>
              </MDBAlert>
            </MDBModal>

            </MDBContainer>
        </div>

    );
  }
}
 