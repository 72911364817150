var env2 = require("../../config/env.js")

export const list = async(params ) =>{

    const response = await fetch((env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/commerces', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      });
      return response.json()
  }
  
  export const create = async(params) =>{
  

    const response = await fetch( (env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/commerces', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    });
  
    return response.json()
  
  }
  export const destroy = async(params) =>{
  
    const response = {}
    return response.json()
  
  }
  
  export const get = async(id) =>{
  
    const response = await fetch((env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/commerces/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      }
    });
    return response.json()
   
  }
  
  export const update = async(params) =>{
  
    const response = {}
    return response.json()
  
  }
  