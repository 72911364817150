var env2 = require("../../config/env.js")


export const list = async(params) =>{

  const response = await fetch((env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/payments', {
     method: 'GET',
     headers: {
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + sessionStorage.getItem('token')
     },
     params: JSON.stringify(params)
   });

   return response.json()
}

export const listtransactions = async(params ) =>{

  const response = await fetch((env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/payments', {
     method: 'GET',
     headers: {
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + sessionStorage.getItem('token')
     },
     params: JSON.stringify(params)
   });

   return response.json()
}

export const get = async(id ) =>{

  const response = await fetch((env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/payments/' + id, {
     method: 'GET',
     headers: {
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + sessionStorage.getItem('token')
     }
   });

   return response.json()
}

export const paygold = async(params ) =>{
  
  const response = await fetch((env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/payments/paygold', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    },
    body: JSON.stringify(params)
    });

  return response.json();
}

export const update = async( id, params ) =>{

  const response = await fetch((env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/api/v' + env2.API_VERSION + '/payments/' + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    });
    return response.json()
}
export const resume = async(params ) =>{

  const response = await fetch((env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/api/v' + env2.API_VERSION + '/payments/resume', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    });
    return response.json()
}

export const transaction = async(params ) =>{

  const response = await fetch((env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/payments/transactions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    });
    return response.json()
}

export const create = async(params ) =>{

  var response = {};
  var check = true;
  if (params.amount === undefined) 
    check = false;
  if (params.currency_id === undefined) 
    check = false;
  if (params.name === undefined) 
    check = false;

  if (check)  {
    
    const resp = await fetch((env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/payments', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    },
    body: JSON.stringify(params)
    });

    response = resp.json();
    
  } else {
    response = {
      data: [],
      code: 300,
      description: "Faltan datos o formato de datos incorrectos.",
      solution: "Revise los campos del formulario",
      encrypted_data: ""
    }
  }

  return response


}
