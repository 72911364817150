import React, { Component, useState  } from "react";
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBFormInline,
MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBContainer,MDBIcon,MDBSelect } from "mdbreact";
import {get} from "../Users/functions";
import {list as listcurrencies, getrate} from "../Currencies/functions";

const $ = require('jquery');

var current_currency = {code: "EUR", rate: 1, locate: "es-CU", last_date_rate: ""};

class Menu extends Component {
  constructor(props, context) {
    super(props, context);

  }

  componentWillUnmount() {
    $(document).unbind();
  }

  componentDidMount() {
    var tt = this;

    let myPromise = new Promise((resolve, reject) => {
      const pp = listcurrencies();
      resolve(pp);
    });
    myPromise.then( (results) => {
      tt.setState({
        rawcurrencies:  results.data.map(object => ({code: object.code, locale: object.locale, name: object.name, rate: object.rate, last_date_rate: object.last_date_rate})),
        currencies: results.data.map(object => ({text: object.code, value: object.code})),
        comparecurrencies: results.data.map(object => ({text: object.code, value: object.code}))
      }, () => {
        tt.onChangeCurrency(["USD"])

      })
    });

    myPromise = new Promise((resolve, reject) => {
      const pp = get();
      resolve(pp);
    });
    myPromise.then( (results) => {
      if (results.data.length !==0) {
        tt.setState({
          user: results.data[0]
        })
      } else {
        sessionStorage.setItem('token', "");
        window.location.href = "/";
    
      }
    });

  }



  state = {
    collapseID: "",
    currencies: [],
    comparecurrencies: [],
    rawcurrencies: [],
    rate: 1,
    currency: undefined,
    comparecurrency: undefined,
    user: {
      name: ""
    }
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
  }));

  onChangeCurrency = event => {
    
    if (event.length !== 0) {
      try {
        current_currency = this.state.rawcurrencies.filter(function(item) { return item.code === event[0]; })[0];
        this.setState({
          rate:  new Intl.NumberFormat(current_currency.locale, { style: 'currency', currency: "EUR"  }).format( (1/parseFloat(current_currency.rate))), 
        });
      } catch(e) {console.log("E: ", e)}
    }
  }

  render() {

    return (

      <MDBContainer fluid style={{background: "white"}}>
      <MDBContainer>
          <MDBNavbar  light expand="md menu-top">
            <MDBNavbarBrand>
              <img src="/logo.png" width={"180"}/>
          </MDBNavbarBrand>
            <MDBNavbarToggler onClick={this.toggleCollapse("navbarCollapse3")} />
            <MDBCollapse id="navbarCollapse3" isOpen={this.state.collapseID} navbar>
              <MDBNavbarNav right>
                {this.state.user.role !== 2 &&
                (<>
                <MDBNavItem active>
                  <MDBNavLink to="/"><MDBIcon />Dashboard</MDBNavLink>
                </MDBNavItem>
                <MDBNavItem >
                  <MDBNavLink to="/productos">Productos</MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="/pagos">Pagos</MDBNavLink>
                </MDBNavItem>
                </>)}
                {this.state.user.role === 1 &&
                (<>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret>
                      <span className="mr-2">Informes </span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu basic left>
                    <MDBDropdownItem><MDBNavLink  to="/reports/sales/">Informe de ventas</MDBNavLink></MDBDropdownItem>
                    <MDBDropdownItem><MDBNavLink to="#">Informe resumido</MDBNavLink></MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="/users">Usuarios</MDBNavLink>
                </MDBNavItem>
                </>
                )}
                {this.state.user.role === 2 &&
                (<>
                <MDBNavItem>
                  <MDBNavLink to="/commerces">Comercios</MDBNavLink>

                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="/users">Usuarios</MDBNavLink>
                </MDBNavItem>
                </>
                )}

                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret>
                      <span className="mr-2">{this.state.user.name.substring(0, 20)}</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem><MDBNavLink  to="/users/profile/">Mi Perfil</MDBNavLink></MDBDropdownItem>
                      <MDBDropdownItem divider />
                      <MDBDropdownItem><MDBNavLink  to="/users/logout/">Cerrar sesión</MDBNavLink></MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem >
                  <MDBSelect 
                    options= {this.state.currencies}
                    getValue={this.onChangeCurrency}
                    selected= {(this.state.currency===undefined) ? "USD" : this.state.item.currency}
                  />
                </MDBNavItem>
                <MDBNavItem  style={{paddingTop: "10px"}}>
                  <div>{this.state.rate} {this.state.currency}</div>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>
          </MDBContainer>
        </MDBContainer>
      );
    }
  }


export default Menu;