var env2 = require("../../config/env.js")

export const list = async(params ) =>{

  const response = await fetch((env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/users', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
      params: JSON.stringify(params)
    });
    return response.json()

}

export const getById = async(id) =>{

  const response = await fetch( (env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/users/'+ id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
  });

  return response.json()

}

export const get = async() =>{

  const response = await fetch( (env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/users/'+ sessionStorage.getItem('token'), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
  });

  return response.json()

}

export const destroy = async(id) =>{

  const response = await fetch( (env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/users/'+ id, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
  });

  return response.json()

}

export const create = async(params) =>{
  var response = {};
  var description_error = ""
  var check = true;
  if (params.role === undefined)  {
    check = false;
  }
  if (params.password !== params.repeatpassword) { 
    description_error= "Contraseñas no coinciden";
    check = false;
  }
  if (params.commerce_id === undefined) {
    check = false;
  }
  delete params.repeatpassword

  if (check)  {

    const resp = await fetch( (env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    });
    response = resp.json();

  } else {
    response = {
      data: [],
      code: 300,
      description: (description_error === "") ? "Faltan datos o formato de datos incorrectos." : description_error,
      solution: "Revise los campos del formulario",
      encrypted_data: ""
    }

  }
  return response

}

export const update = async(id, params) =>{


  const response = await fetch( (env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/users/' + id, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    },
    body: JSON.stringify(params)
  });

  return response.json()

}

export const updatepass = async(id, params) =>{


  const response = await fetch( (env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/users/updatepass/' + id, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    },
    body: JSON.stringify(params)
  });

  return response.json()

}

export const login = async(credentials) =>{
  const response = await fetch(  (env2.API_SERVER_NAME_DEVELOPMENT || env2.API_SERVER_NAME_PRODUCTION) + '/auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  });
  
  return response.json()
   
}