import React, { useState } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCol, MDBContainer, MDBRow} from "mdbreact";

import PropTypes from 'prop-types';

import {login} from "../Users/functions";

function validate(msg) {
  if (msg.code === 200) {
    return true
  } else {
    return false;
  }

}

export default function Login({ setToken }) {


  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const handleSubmit = async e => {
    e.preventDefault();
    const user = await login({
      username,
      password
    });
    console.log("USER: ", user.code)
    if ( validate(user) ) {
      setToken(user.token);
    } else {
      alert("Usuario y/o contraseña incorrectos.")
    }
  }
    
  return(
  <>
    <MDBContainer>
      <MDBRow center style={{ height: "100vh" }}>
        <MDBCol middle="true" sm="4" className="text-center">
          <MDBCard>
            <MDBCardBody>
              <div className="login-wrapper">
                <img src="/logo.png" width={"200"}/>
                <p>Acceder</p>
                <form onSubmit={handleSubmit}>
                  <MDBRow>
                    <MDBCol>
                      <p>Usuario</p>
                      <input type="text" onChange={e => setUserName(e.target.value)} />
                    </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                      <p>Contraseña</p>
                      <input type="password" onChange={e => setPassword(e.target.value)} />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                    <div className="invalid-tooltip">
                      asdasd
                      </div>

                    <MDBCol>
                      <MDBBtn  type="submit">Entrar</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </form>
              </div>
            </MDBCardBody>
            <MDBCardFooter>
              <label>gestioncomercios.com v.0.8.000</label>
            </MDBCardFooter>
          </MDBCard>
        </MDBCol>
      </MDBRow>

    </MDBContainer>
    </>
  )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
  }
  