import { MDBCard, MDBCardBody, MDBContainer,MDBBreadcrumb,MDBBreadcrumbItem, MDBRow, MDBCol, MDBCardTitle } from "mdbreact";
import React, { Component } from "react";
import i18 from 'i18next';

import {resume} from "../Payments/functions";
import {get} from "../Users/functions";

class Dashboard extends Component {

  state = {
    payday: 0,
    payyesterday: 0,
    payweek: 0,
    paymonth:0,
    devday: 0,
    devyesterday:0,
    devweek: 0,
    devmonth: 0
  }

  componentDidMount() {
    var tt = this;

    var user = {};
    let myPromise = new Promise((resolve, reject) => {
      const pp = get();
      resolve(pp);
    });
    myPromise.then( (results) => {
      if (results.data.length !==0) {
          user = results.data[0]
      } else {
        sessionStorage.setItem('token', "");
        window.location.href = "/";
      }
      console.log("user: ", user.role)
      if (user.role === 2)
        window.location.href = "/commerces"
    });
     myPromise = new Promise((resolve, reject) => {
      const pp = resume({"state": [1]});
      resolve(pp);
    });

    myPromise.then( (results) => {
      tt.setState({
        payday: new Intl.NumberFormat("es-CU", { style: 'currency', currency: "EUR" }).format(results.data[0].p.day),
        payyesterday: new Intl.NumberFormat("es-CU", { style: 'currency', currency: "EUR" }).format(results.data[0].p.yesterday),
        payweek: new Intl.NumberFormat("es-CU", { style: 'currency', currency: "EUR" }).format(results.data[0].p.week),
        paymonth:  new Intl.NumberFormat("es-CU", { style: 'currency', currency: "EUR" }).format(results.data[0].p.month), 
        devday: new Intl.NumberFormat("es-CU", { style: 'currency', currency: "EUR" }).format(results.data[0].d.day), 
        devyesterday: new Intl.NumberFormat("es-CU", { style: 'currency', currency: "EUR" }).format(results.data[0].d.yesterday),
        devweek:new Intl.NumberFormat("es-CU", { style: 'currency', currency: "EUR" }).format(results.data[0].d.week),
        devmonth: new Intl.NumberFormat("es-CU", { style: 'currency', currency: "EUR" }).format(results.data[0].d.month), 
      });
      
    });
    sessionStorage.setItem('previous-page', this.props.location.pathname) 

  }
  render() {
    return (
      <MDBContainer >
          <MDBBreadcrumb>
          <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>Dashboard</MDBBreadcrumbItem>
        </MDBBreadcrumb>        
        <MDBRow>
            <MDBCol lg="6" sm="12">
              <MDBCard>
                <MDBCardBody>
                <MDBCardTitle tag="h5" style={{position: "relative", top: "19px"}}>TOTAL PAGOS</MDBCardTitle>
                <hr/>                
                <p>Hoy: <span class="grey-text float-right">{this.state.payday}</span></p>
                <p>Ayer: <span class="grey-text float-right">{this.state.payyesterday}</span></p>
                <p>Esta semana: <span class="grey-text float-right">{this.state.payweek}</span></p>
                <p>Este mes: <span class="grey-text float-right">{this.state.paymonth}</span></p>
              </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol lg="6" sm="12">
              <MDBCard>
                <MDBCardBody>
                  <MDBCardTitle tag="h5" style={{position: "relative", top: "19px"}}>TOTAL DEVOLUCIONES</MDBCardTitle>
                  <hr/>                
                  <p>Hoy: <span class="grey-text float-right">{this.state.devday}</span></p>
                  <p>Ayer: <span class="grey-text float-right">{this.state.devyesterday}</span></p>
                  <p>Esta semana: <span class="grey-text float-right">{this.state.devweek}</span></p>
                  <p>Este mes: <span class="grey-text float-right">{this.state.devmonth}</span></p>
                
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default Dashboard;